import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from 'gatsby'
import { useActiveDoc } from '../contexts/ActiveDocProvider'
import { useTranslation } from '../contexts/TranslationProvider'

const Footer = ({ menu }) => {
  const { activeDoc } = useActiveDoc()
  const { t } = useTranslation()

  const data = useStaticQuery(query)

  const footerMenu = data.footerMenu.edges
    .filter(edge => edge.node.lang === activeDoc.lang)
    .map(r => r.node.data.links)
    .pop()

  const allLanguages = activeDoc.allLanguages

  if (allLanguages.length < 0) return

  const handleChange = event => {
    navigate(`/${event.target.value}`)
  }

  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-4'>
            <h3>{t('helpNeeded')}</h3>
            <a href="tel://+40749680770" id='phone'>
              +40 749 680 770
            </a>
            <a href="mailto:contact@turistintransilvania.com" id='email_footer'>
              contact@turistintransilvania.com
            </a>
            <br />
          </div>
          <div className='col-12 col-lg-5'>
            <h3>{t('aboutUs')}</h3>
            <ul>
              {footerMenu.map((item, key) => (
                <li key={`footer-menu-entry-${key}`}>
                  <a href={item.url.url}>{item.label}</a>
                </li>
              ))}
            </ul>
            <br />
          </div>
          <div className='col-12 col-lg-3'>
            <h3>{t('language')}</h3>
            <div className='styled-select'>
              <select
                value={activeDoc.url}
                className='form-control'
                name='lang'
                id='lang'
                onChange={handleChange}
              >
                {allLanguages.map((altLang, index) => (
                  <option key={`lang-selector-${index}`}
                    value={altLang.url}>
                    {altLang.lang.slice(0, 2)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div id='social_footer'>
              <ul>
                <li>
                  <a
                    href="https://facebook.com/turistintransilvania"
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/touristintransylvania"
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-instagram'></i>
                  </a>
                </li>
              </ul>
              <p>© Turist in Transilvania {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const query = graphql`
  query FooterQuery {
    footerMenu: allPrismicFooterMenuAboutUs {
      edges {
        node {
          lang
          data {
            title
            links {
              label
              url {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
  }
`

export default Footer