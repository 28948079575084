/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
const langMap = {
    'en-gb': 'en',
    'de-de': 'de',
    'ro': 'ro'
}

const parentMap = {
    'news': {
        'en-us': 'news',
        'ro': 'noutati',
        'de': 'neuigkeiten'
    }
}

const mapLang = (doc) => langMap[doc.lang] ?? doc.lang
const mapParent = (doc) => parentMap[doc.type][doc.lang] ?? ''

exports.linkResolver = (doc) => {
    switch (doc.type) {
        case 'homepage': {
            return `/${mapLang(doc)}/`
        }

        case 'static_page': {
            return `/${mapLang(doc)}/${doc.uid}/`
        }

        default:
            return `/${mapLang(doc)}/`
    }
}

exports.mapLang = mapLang
exports.mapParent = mapParent