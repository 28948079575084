import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import { useTranslation } from '../contexts/TranslationProvider'
import { useActiveDoc } from '../contexts/ActiveDocProvider'
import { linkResolver } from '../utils/linkResolver'

const Header = () => {
  const [isActive, setIsActive] = useState(false)
  const { activeDoc } = useActiveDoc()
  const { t } = useTranslation()
  const data = useStaticQuery(query)

  const mainMenu = data.mainMenu.edges
    .filter(edge => edge.node.lang === activeDoc.lang)
    .map(r => r.node.data.links)
    .pop()

  const allLanguages = activeDoc.allLanguages

  if (allLanguages.length < 0) return

  return (
    <header id='plain'>
      <div id='top_line'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 col-lg-8'>
              <a href='tel://+40749680770'>
                <i className='icon-phone'></i>
                <b>+40 749 680 770</b>
              </a>
              <span id='opening'>
                <i className=' icon-clock-1'></i>
                {t('schedule')}
              </span>
            </div>

            <div className='col-md-5 col-lg-4'>
              <ul className='language_links'>
                {allLanguages.map((altLang, index) => (
                  <li key={`top-nav-lang-${index}`}>
                    <Link
                      to={linkResolver(altLang)}
                      className={
                        altLang.active ? 'active' : ''
                      }
                    >
                      {altLang.lang.slice(0, 2).toUpperCase()}
                    </Link>
                  </li>
                ))}
                <li>
                  <a
                    href="https://facebook.com/turistintransilvania"
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/touristintransylvania"
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-instagram'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col col-md-3 col-sm-3 col-xs-3'>
            <div className='logo_home'>
              <h1>
                <Link to={activeDoc.url}>Turist in Transilvania</Link>
              </h1>
            </div>
          </div>

          <nav className='col col-md-9 col-sm-9 col-xs-9'>
            <button
              type='button'
              className='cmn-toggle-switch cmn-toggle-switch__htx open_close'
              onClick={() => setIsActive(!isActive)}
              onKeyDown={() => setIsActive(!isActive)}
            >
              <span>Menu mobile</span>
            </button>
            <div className={`main-menu ${isActive ? 'show' : ''}`}>
              <div id='header_menu'>
                <div className='logo_home'>
                  <h1>
                    <Link to={activeDoc.url}>Turist in Transilvania</Link>
                  </h1>
                </div>
              </div>
              <button
                type='button'
                className='open_close d-lg-none'
                onClick={() => setIsActive(!isActive)}
              >
                <i className='icon_set_1_icon-77'></i>
              </button>
              <ul>
                <li>
                  <Link to={activeDoc.url}>Home</Link>
                </li>
                {mainMenu.map((item, key) => (
                  <li key={`main-menu-entry-${key}`}>
                    <a href={item.url.url}>{item.label}</a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
        <div className='row'>
          <div className='col col-12'>
            <ul className='language_links d-lg-none d-md-none'>
              {allLanguages.map((altLang, index) => (
                <li key={`middle-nav-lang-${index}`}>
                  <Link
                    to={linkResolver(altLang)}
                    className={altLang.active ? 'active' : ''}
                  >
                    {altLang.lang.slice(0, 2).toUpperCase()}
                  </Link>
                </li>
              ))}

              <li>
                <a
                  href="https://facebook.com/turistintransilvania"
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='icon-facebook'></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/touristintransylvania"
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='icon-instagram'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

const query = graphql`
  query HeaderQuery {
    mainMenu: allPrismicHeaderMenu {
      edges {
        node {
          lang
          data {
            title
            links {
              label
              url {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
  }
`

export default Header