import * as React from 'react'

import Layout from './src/components/Layout';

import ActiveDocProvider from './src/contexts/ActiveDocProvider';
import TranslationProvider from './src/contexts/TranslationProvider';

import i18n from "./src/config/i18n"

export const wrapPageElement = ({ element, props }) => {
    return (
        <ActiveDocProvider pageContext={props.pageContext}>
            <TranslationProvider i18n={i18n}>
                <Layout {...props}>{element}</Layout>
            </TranslationProvider>
        </ActiveDocProvider>
    )
}
