module.exports = {
  ro: {
    path: 'ro',
    locale: 'ro-ro',
    siteLanguage: 'ro',
    ogLang: 'ro_RO',
    hrefLang: 'ro-RO',
    ogUrl: 'https://www.turistintransilvania.com/ro',
    title:
      'Circuite turistice, cazare și experiențe în Transilvania - Turist în Transilvania',
    description:
      'Descoperă circuite turistice, spații de cazare originale și experiențe unice în Transilvania',
    headline: 'Turist in Transilvania',
    schedule: 'Luni - Duminică: 10:00 - 18:00',
    phone: '+40 749 680 770',
    mail: 'contact@turistintransilvania.com',
    langName: 'Română',
    german: 'Deutsch',
    germanShort: 'DE',
    romanian: 'Română',
    romanianShort: 'RO',
    english: 'English',
    englishShort: 'EN',
    helpNeeded: 'Ai nevoie de ajutor?',
    aboutUs: 'Despre noi',
    language: 'Limbă',
  },
  'de-de': {
    path: 'de',
    locale: 'de-de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    hrefLang: 'de-DE',
    ogUrl: 'https://www.turistintransilvania.com/de',
    title:
      'Tagesausflüge, Unterkünfte und Erlebnisse in Transsilvanien - Tourist in Transsilvanien',
    description:
      'Entdecke Tagesausflüge, eigenartige Unterkünfte und wundervolle Erlebnisse in Transsilvanien',
    headline: 'Tourist in Transsilvanien',
    schedule: 'Montag - Freitag: 10:00 - 18:00',
    phone: '+40 749 680 770',
    mail: 'contact@turistintransilvania.com',
    langName: 'Deutsch',
    german: 'Deutsch',
    germanShort: 'DE',
    romanian: 'Română',
    romanianShort: 'RO',
    english: 'English',
    englishShort: 'EN',
    fbUrl: 'https://facebook.com/turistintransilvania',
    instaUrl: 'https://www.instagram.com/touristintransylvania',
    helpNeeded: 'Brauchst Du Hilfe?',
    aboutUs: 'Über Uns',
    language: 'Sprache',
  },
  'en-gb': {
    path: 'en',
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_GB',
    hrefLang: 'en-GB',
    ogUrl: 'https://www.turistintransilvania.com/en',
    title:
      'Tours, accommodations and experiences in Transylvania - Tourist in Transylvania',
    description:
      'Discover daily tours, unique accommodations and real experiences in Transylvania',
    headline: 'Tourist in Transylvania',
    schedule: 'Monday - Sunday: 10:00 - 18:00',
    phone: '+40 749 680 770',
    mail: 'contact@turistintransilvania.com',
    langName: 'English',
    german: 'Deutsch',
    germanShort: 'DE',
    romanian: 'Română',
    romanianShort: 'RO',
    english: 'English',
    englishShort: 'EN',
    fbUrl: 'https://facebook.com/turistintransilvania',
    instaUrl: 'https://www.instagram.com/touristintransylvania',
    helpNeeded: 'Do you need help?',
    aboutUs: 'About Us',
    language: 'Language',
  },
}
